import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AnimationEvent } from '@angular/animations';

import { TOAST_CONFIG_TOKEN, ToastConfig, ToastData } from './toast-config';
import { ToastRef } from './toast-ref';
import { toastAnimations, ToastAnimationState } from './toast-animation';

@Component({
  selector: 'citydeals-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['toast.component.scss'],
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  animationState: ToastAnimationState = 'default';
  iconType: string;
  hibalista = [];
  public intervalId!: number;

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
    public cdr: ChangeDetectorRef,
    private router: Router,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  ) {
    this.iconType = data.type === 'success' ? 'done' : data.type;
    router.events.subscribe((val) => {
      // Bezárom navigációkor az error-t, Ricsi kérte. Roli
      this.close();
    });
  }

  ngOnInit() {
    this.hibalista = [];
    for (const prop in this.data.text as any) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.data?.text?.hasOwnProperty(prop)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.hibalista = this.hibalista.concat(this.data.text[prop]);
      }
    }
    this.cdr.detectChanges();
    // this.intervalId = setTimeout(() => this.animationState = 'closing', 5000);
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
