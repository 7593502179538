import {
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { SocialLoginAction } from '../../state/action/social-login.action';
import { environment } from '@citydeals/env';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { RegisterAction } from '../../state/action/register.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SuccessfulRegistrationComponent } from '../modals/successful-registration/successful-registration.component';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frontend-auth-client-register',
  templateUrl: './auth-client-register.component.html',
  styleUrls: [
    '../../../../../styles/src/lib/layouts/auth.common.scss',
    './auth-client-register.component.scss',
  ],
})
export class AuthClientRegisterComponent implements OnInit {
  hide = true;
  rehide = true;

  //capcha
  //https://www.google.com/recaptcha
  //domains -> localhost, borz01.lan, citydeals.demo.mmsone.com

  public siteKey = environment.recaptchaSiteKey;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public theme: 'dark' | 'light' = 'light';
  public lang = 'en';
  public size: 'compact' | 'normal' = 'normal';
  public type: 'audio' | 'image' = 'image';
  //

  clientRegisterForm = this.fb.group({
    firstName: [
      '',
      [
        Validators.required /*Validators.pattern('^[A-ZÁÉÚŐÓÜÖÍ][a-záéúőóüöí0-9_-]{2,19}$')*/,
      ],
    ],
    lastName: [
      '',
      [
        Validators.required /*Validators.pattern('^[A-ZÁÉÚŐÓÜÖÍ][a-záéúőóüöí0-9_-]{2,19}$')*/,
      ],
    ],
    email: ['', [Validators.required, Validators.email]],
    password: [
      '',
      [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$'),
      ],
    ],
    rePassword: ['', [Validators.required, this.matchValues('password')]],
    country: ['Magyarország'],
    zipcode: ['', [Validators.required]],
    settlement: ['', [Validators.required]],
    addressName: ['', [Validators.required]],
    // addressType: [''],
    addressNum: ['', [Validators.required]],
    gender: ['MALE'],
    birthdate: [''],
    newsletter: true,
    // recaptcha: ['', Validators.required],
    recaptcha: ['', Validators.required],
    privacyPolicy: [false, Validators.requiredTrue],
    taxNumber: [''],
    companyName: [''],
    companyRegistration: false,
  });

  readonly isBrowser: boolean;

  password() {
    return (
      this.clientRegisterForm.get('password')?.value !==
      this.clientRegisterForm.get('rePassword')?.value
    );
    // if(this.clientRegisterForm.get('password').value!=this.clientRegisterForm.get('rePassword').value){
    //     return false;
    // }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder,
    private store: Store,
    @Inject(LOCALE_ID) private locale: string,
    private actions$: Actions,
    private router: Router,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.actions$
      .pipe(ofActionSuccessful(RegisterAction), untilDestroyed(this))
      .subscribe(() => {
        this.spinnerService.hide();
        this.dialog.open(SuccessfulRegistrationComponent, {
          width: '500px',
        });
        this.router.navigate(['/auth', 'login']);
      });
  }

  ngOnInit() {
    this.setRequiredFields();
  }

  setRequiredFields() {
    const firstName = this.clientRegisterForm.get('firstName');
    const lastName = this.clientRegisterForm.get('lastName');
    const companyName = this.clientRegisterForm.get('companyName');
    const taxNumber = this.clientRegisterForm.get('taxNumber');
    this.clientRegisterForm
      .get('companyRegistration')
      ?.valueChanges.subscribe((companyRegistration) => {
        if (companyRegistration === true) {
          firstName?.setValidators(null);
          lastName?.setValidators(null);
          companyName?.setValidators([Validators.required]);
          taxNumber?.setValidators([Validators.required]);
        } else {
          firstName?.setValidators([Validators.required]);
          lastName?.setValidators([Validators.required]);
          companyName?.setValidators(null);
          taxNumber?.setValidators(null);
        }

        firstName?.updateValueAndValidity();
        lastName?.updateValueAndValidity();
        companyName?.updateValueAndValidity();
        taxNumber?.updateValueAndValidity();
      });
  }

  matchValues(
    matchTo: string
  ): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }

  onSubmit() {
    const formdata = this.clientRegisterForm.value;
    const regData = {
      aszfCheck: formdata.privacyPolicy,
      email: formdata.email,
      firstName: formdata.firstName,
      lastName: formdata.lastName,
      newsCheck: formdata.newsletter,
      password: formdata.password,
      password_confirmation: formdata.rePassword,
      privacyPolicy: formdata.privacyPolicy,
      country: 'Magyarország', // formdata.country,
      zipcode: String(formdata.zipcode),
      settlement: formdata.settlement,
      addressName: formdata.addressName,
      // addressType: formdata.addressType,
      addressNum: formdata.addressNum,
      gender: 'MALE', // formdata.gender,
      birthdate: formdata.birthdate
        ? formatDate(formdata.birthdate, 'YYYY-MM-dd', this.locale)
        : '',
      companyRegistration: formdata.companyRegistration,
      companyName: formdata.companyName,
      taxNumber: formdata.taxNumber,
    };
    const userType = 'CLIENT';
    this.store.dispatch(new RegisterAction(regData, userType));
    this.spinnerService.show();
  }

  public hasError(controlName: string, errorName: string) {
    return this.clientRegisterForm.controls[controlName].hasError(errorName);
  }

  isCompanyRegistration() {
    return this.clientRegisterForm.get('companyRegistration')?.value;
  }

  //capcha
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }
  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  socialLogin(provider: string) {
    this.store.dispatch(new SocialLoginAction(provider));
    // this.authService.socialLogin(provider).subscribe((res) => {
    //   window.location.href = res.data.redirect;
    // });
  }
}
