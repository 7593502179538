<div class="purchases__box" *ngIf="vouchers$ | async as vouchers" >
  <div class="purchases__table" *transloco="let t; read: 'PAGE'">
    <div
      *ngIf="!('' | isMobileDevice)"
      class="purchases__table-row purchases__table-row--header"
    >
      <div
        class="purchases__table-col purchases__table-col--header purchases__table-col--voucher"
      >
        {{ t('PURCHASES.GRID.VOUCHER_COLUMN_TEXT') }}
      </div>
      <div
        class="purchases__table-col purchases__table-col--header purchases__table-col--center purchases__table-col--validity"
      >
        {{ t('PURCHASES.GRID.VALIDITY_COLUMN_TEXT') }}
      </div>
      <div
        class="purchases__table-col purchases__table-col--header purchases__table-col--center purchases__table-col--price"
      >
        {{ t('PURCHASES.GRID.PRICE_COLUMN_TEXT') }}
      </div>
      <div
        class="purchases__table-col purchases__table-col--header purchases__table-col--center purchases__table-col--status"
      >
        {{ t('PURCHASES.GRID.STATUS_COLUMN_TEXT') }}
      </div>
      <div
        class="purchases__table-col purchases__table-col--header purchases__table-col--center purchases__table-col--actions"
      >
        {{ t('PURCHASES.GRID.FUNCTIONS_COLUMN_TEXT') }}
      </div>
    </div>
    <div
      class="purchases__table-row"
      *ngFor="let voucher of vouchers; trackBy: trackByFn"
    >
      <div class="purchases__table-col purchases__table-col--voucher">
        <a
          [href]="backendUrl + '/client/pdfdownload/' + voucher.code"
          target="_blank"
          class="purchases__voucher"
        >
          <div class="d-flex align-items-start flex-column flex-sm-row">
            <citydeals-card-image
              class="purchases__voucher__image mb-2 mb-sm-0 flex-shrink-0 flex-grow-0"
              [images]="[voucher.offer_image]"
              [discount]="getDiscount(voucher.packageDiscountValue)"
              [discountType]="voucher.packageDiscountType"
              [disableHover]="true"
              color="secondary"
            ></citydeals-card-image>
            <div class="w-100 flex-grow-sm-1 ms-0 ms-sm-3">
              <h4 class="purchases__voucher__title">
                {{ voucher.offerTitle }}
              </h4>
              <ng-container *ngIf="'' | isMobileDevice">
                <div
                  class="purchases__voucher__data d-flex justify-content-between align-items-center"
                >
                  <span>{{ t('PURCHASES.GRID.VALIDITY_COLUMN_TEXT') }}:</span>
                  <span class="text-end"
                    >{{ voucher.offerValidFrom | date: 'yyyy.MM.dd' }} -
                    {{ voucher.offerValidEnd | date: 'yyyy.MM.dd' }}</span
                  >
                </div>
                <div
                  class="purchases__voucher__data d-flex justify-content-between align-items-center"
                >
                  <span>{{ t('PURCHASES.GRID.PRICE_COLUMN_TEXT') }}:</span>
                  <span class="text-end">{{
                    voucher.packagePrice | currency: 'EUR':'€':'2.0-0'
                  }}</span>
                </div>
                <div
                  class="purchases__voucher__data d-flex justify-content-between align-items-center"
                >
                  <span>{{ t('PURCHASES.GRID.STATUS_COLUMN_TEXT') }}:</span>
                  <span class="text-end">{{ voucher.status }}</span>
                </div>
                <div
                  class="purchases__voucher__data d-flex justify-content-between align-items-center"
                >
                  <span>{{ t('PURCHASES.GRID.FUNCTIONS_COLUMN_TEXT') }}:</span>
                  <a
                    [href]="backendUrl + '/client/pdfdownload/' + voucher.code"
                    target="_blank"
                    class="cd-btn cd-btn--green"
                    >{{ t('PURCHASES.GRID.DOWNLOAD_BUTTON_TEXT') }}</a
                  >
                </div>
              </ng-container>
            </div>
          </div>
        </a>
      </div>
      <div
        *ngIf="!('' | isMobileDevice)"
        class="purchases__table-col purchases__table-col--center purchases__table-col--validity"
      >
        {{ voucher.offerValidFrom | date: 'yyyy.MM.dd' }} -
        {{ voucher.offerValidEnd | date: 'yyyy.MM.dd' }}
      </div>
      <div
        *ngIf="!('' | isMobileDevice)"
        class="purchases__table-col purchases__table-col--center purchases__table-col--price"
      >
        {{ voucher.packagePrice | currency: 'EUR':'€':'2.0-0' }}
      </div>
      <div
        *ngIf="!('' | isMobileDevice)"
        class="purchases__table-col purchases__table-col--center purchases__table-col--status"
      >
        {{ voucher.status }}
      </div>
      <div
        *ngIf="!('' | isMobileDevice)"
        class="purchases__table-col purchases__table-col--center purchases__table-col--actions"
      >
        <a
          [href]="backendUrl + '/client/pdfdownload/' + voucher.code"
          target="_blank"
          class="cd-btn cd-btn--green"
          >{{ t('PURCHASES.GRID.DOWNLOAD_BUTTON_TEXT') }}</a
        >
      </div>
    </div>
  </div>
</div>
