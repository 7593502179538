import { RegisterPayloadEntity } from '../../entity/register-payload.entity';
import { UserType } from '../../old-auth.service';

export class RegisterAction {
  static type = '[AUTH] Register';

  constructor(
    readonly payload: RegisterPayloadEntity,
    readonly userType: UserType
  ) {}
}
