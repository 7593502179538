import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CitydealsFormGroup, matchValuesValidator } from '@citydeals/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ResetPasswordAction } from '../../state/action/reset-password.action';

@UntilDestroy()
@Component({
  selector: 'frontend-client-password',
  templateUrl: './client-password.component.html',
  styleUrls: [
    '../../../../../styles/src/lib/layouts/auth.common.scss',
    './client-password.component.scss',
  ],
})
export class ClientPasswordComponent implements OnInit {
  emailControl = new FormControl(
    '',
    Validators.compose([Validators.email, Validators.required])
  );
  tokenControl = new FormControl('', Validators.required);
  passwordControl = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$'),
    ])
  );
  passwordConfirmControl = new FormControl(
    '',
    Validators.compose([Validators.required, matchValuesValidator('password')])
  );
  form = new CitydealsFormGroup({
    email: this.emailControl,
    token: this.tokenControl,
    password: this.passwordControl,
    passwordConfirm: this.passwordConfirmControl,
  });
  hide = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private store: Store
  ) {
    this.actions$
      .pipe(ofActionSuccessful(ResetPasswordAction), untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/auth', 'login']);
      });
  }

  ngOnInit() {
    this.form.patchValue({ token: this.route.snapshot.paramMap.get('id') });
  }

  resetPassword() {
    if (this.form.invalid) {
      return;
    }

    this.store.dispatch(new ResetPasswordAction(this.form.value));
  }
}
