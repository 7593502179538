<ng-container
  *ngIf="
    control.invalid &&
    (parentFormGroup.submitted || control.touched || control.dirty)
  "
>
  <ng-container *ngFor="let messageType of messageTypes">
    <ng-container *ngIf="control.hasError(messageType.type)">{{
      messageType.msg
    }}</ng-container>
  </ng-container>
</ng-container>
