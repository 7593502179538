import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@citydeals/auth').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('@citydeals/app-layout').then((m) => m.AppLayoutModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('@citydeals/pages-payment').then((m) => m.PagesPaymentModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('@citydeals/voucher/preview').then((m) => m.VoucherPreviewModule),
  },
  {
    path: '**',
    redirectTo: '404-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
