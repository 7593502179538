import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AuthState} from './state/auth.state';
import {debugLog} from '@citydeals/common';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // debugLog('call url:', req.url);
    // debugLog('loggedIn:', this.store.selectSnapshot(AuthState.isLoggedIn));
    if (this.store.selectSnapshot(AuthState.isLoggedIn)) {
      return next.handle(
        req.clone({
          headers: req.headers.set(
            'Authorization',
            `Bearer ${this.store.selectSnapshot(AuthState.token)}`
          ),
        })
      );
    }

    return next
      .handle(req)
      .pipe
      // TODO backendnel szolni hogy ne 401-vel valaszoljon hibas login eseten!
      // catchError((error: HttpErrorResponse) => {
      //   if (error.status === 401) {
      //     this.store.dispatch(LogoutAction);
      //   }
      //
      //   return throwError(() => new Error(error.message));
      // })
      ();
  }
}
