import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {ExtensionToWebpPipe} from "./image-filename-extension-to-webp-pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [ExtensionToWebpPipe],
  exports: [ExtensionToWebpPipe],
})
export class ExtensionToWebpPipeModule {}
