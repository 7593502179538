import { NgModule } from '@angular/core';
import { IsMobilePipe } from './is-mobile.pipe';
import { IsTabletPipe } from './is-tablet.pipe';
import { IsMobileDevicePipe } from './is-mobile-device.pipe';

@NgModule({
  declarations: [IsMobilePipe, IsTabletPipe, IsMobileDevicePipe],
  exports: [IsMobilePipe, IsTabletPipe, IsMobileDevicePipe],
})
export class MobileDetectModule {}
