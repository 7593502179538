<ng-container
  *ngIf="!showMultiple; else carousel"
  [ngTemplateOutlet]="picture"
  [ngTemplateOutletContext]="{ $implicit: images[0] }"
>
</ng-container>
<div *ngIf="badge || discount" class="flag-container">
  <span *ngIf="badge" class="flag flag--badge" [ngClass]="'flag--' + color">
    <img [src]="'/assets/img/badges/' + (badge | lowercase) + '.svg'" loading="lazy"/>
  </span>
  <span *ngIf="discount" class="flag" [ngClass]="'flag--' + color">{{
    discount | discount: discountType
  }}</span>
</div>
<ng-template #carousel>
  <swiper
    [loop]="carouselOptions.loop"
    [slidesPerView]="carouselOptions.slidesPerView"
    [autoplay]="carouselOptions.autoplay"
  >
    <ng-container *ngFor="let image of images">
      <ng-template swiperSlide>
        <ng-container
          [ngTemplateOutlet]="picture"
          [ngTemplateOutletContext]="{ $implicit: image }"
        ></ng-container>
      </ng-template>
    </ng-container>
  </swiper>
</ng-template>
<ng-template #picture let-image>
  <picture class="card-image__picture">
    <source [attr.srcset]="imageBaseUrl + (image | extensionToWebp)" />
    <source [attr.srcset]="imageBaseUrl + image"/>
    <img [attr.src]="imageBaseUrl + image" loading="lazy"alt=""/>
  </picture>
</ng-template>
