import { Observable, take } from 'rxjs';
import { Store } from '@ngxs/store';
import { VoucherEntity } from '@citydeals/category';
import { RemoveBookmarkAction } from './state/action/remove-bookmark.action';
import { SetBookmarkAction } from './state/action/set-bookmark.action';

export function toggleBookmarkFunction(
  isBookmarked$: Observable<boolean>,
  store: Store,
  voucher: VoucherEntity
) {
  isBookmarked$?.pipe(take(1)).subscribe((isBookmarked) => {
    store.dispatch(
      new (isBookmarked ? RemoveBookmarkAction : SetBookmarkAction)(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        voucher!
      )
    );
  });
}
