import { Inject, Injectable } from '@angular/core';
import * as MobileDetect from 'mobile-detect';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MobileDetectService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  private _md: MobileDetect | null = null;

  get md(): MobileDetect {
    if (this._md === null) {
      this._md = new MobileDetect(
        this.document.defaultView?.navigator?.userAgent ??
          /*SSR miatt default*/ 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/101.0.0.0 Safari/537.36'
      );
    }
    return this._md;
  }

  set md(value: MobileDetect) {
    this._md = value;
  }

  isMobile() {
    return this.md.mobile();
  }

  isTablet() {
    return this.md.tablet();
  }
}
