import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
import { EventProduct } from '../models/event-product.model';
import { PageInitEvent } from './page-init.event';

export class ProductPageEvent extends PageInitEvent implements CitydealsEvent {
  private product: EventProduct;

  constructor(userId: number | null, product: EventProduct) {
    super('productpage', userId);
    this.product = product;
  }

  override getEvent(): AnalitycsEvent {
    return {
      ...super.getEvent(),
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: [this.product],
        },
      },
    };
  }
}
