import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  public loadJsScript(src: string): HTMLScriptElement {

    const scriptTag = document.createElement('script');
    scriptTag.src = src;
    scriptTag.defer = true;
    scriptTag.async = true;
    scriptTag.type = 'text/javascript';

    return scriptTag;
  }
}
