import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
  SimpleChanges,
} from '@angular/core';
import { environment } from '@citydeals/env';
import SwiperCore, { Autoplay } from 'swiper';
import { isNil } from '../typeguard/is-nil';
import { isPlatformBrowser } from '@angular/common';

SwiperCore.use([Autoplay]);

@Component({
  selector: 'citydeals-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardImageComponent implements OnChanges {
  @Input() images: string[] = [];
  @Input() badge: string | undefined = undefined;
  @Input() discount: number | undefined = undefined;
  @Input() discountType: boolean | undefined = false;
  @Input() showMultiple = false;
  @Input() color: 'primary' | 'secondary' = 'primary';
  carouselOptions = {
    loop: isPlatformBrowser(this.platformId),
    slidesPerView: 1,
    autoplay: { delay: 2500 },
  };
  @Input() disableHover = false;
  isMouseOver = false;

  @HostBinding('class') classNames = this.generateClassNames();
  imageBaseUrl = environment.backendStorageBaseUrl;

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!isNil(changes['color']) && !isNil(changes['color'].currentValue)) {
      this.classNames = this.generateClassNames();
    }
  }

  private generateClassNames() {
    const classNames = [`card-image--${this.color}`];
    if (this.isMouseOver && !this.disableHover) {
      classNames.push('card-image--hover');
    }
    if (this.disableHover) {
      classNames.push('card-image--no-hover');
    }
    return classNames;
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.isMouseOver = true;
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.isMouseOver = false;
  }
}
