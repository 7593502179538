import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsArrayPipe } from './is-array.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [IsArrayPipe],
  exports: [IsArrayPipe],
})
export class IsArrayPipeModule {}
