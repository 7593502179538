import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DiscountPipeModule, ExtensionToWebpPipeModule} from '@citydeals/common';
import { SwiperModule } from 'swiper/angular';
import { CardImageComponent } from './card-image.component';

@NgModule({
    imports: [CommonModule, DiscountPipeModule, SwiperModule, ExtensionToWebpPipeModule],
  declarations: [CardImageComponent],
  exports: [CardImageComponent],
})
export class CardImageModule {}
