import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef } from '@angular/material/dialog';
import { ForgottenService } from './forgotten.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frontend-forgotten-password-modal',
  templateUrl: './forgotten-password-modal.component.html',
  styleUrls: ['./forgotten-password-modal.component.scss'],
  providers: [ForgottenService],
})
export class ForgottenPasswordModalComponent {
  form = this.fb.group({
    email: [''],
  });
  message = false;
  messageSuccess = true;
  text = '';
  success = false;

  constructor(
    private spinnerService: NgxSpinnerService,
    private forgotten: ForgottenService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ForgottenPasswordModalComponent>
  ) {}

  onSubmit() {
    this.spinnerService.show();
    this.forgotten.sendEmail(this.form.getRawValue()).subscribe(
      (res) => {
        this.spinnerService.hide();
        this.messageSuccess = false;
        this.success = true;
      },
      (error) => {
        this.spinnerService.hide();
        this.messageSuccess = false;
        this.message = true;
        this.text = error.error.message;
      }
    );
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
