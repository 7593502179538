<div>
  <div class="modal-header">
    <h5 class="modal-title modal-title-forgotten">Jelszó visszaállítás</h5>
    <button
      class="close"
      mat-icon-button
      matDialogClose
      tabindex="-1"
      (click)="onClose()"
    >
      <mat-icon aria-label="Close dialog">close</mat-icon>
    </button>
  </div>
  <div class="modal-body" style="padding: 15px !important">
    <div class="forgottenPwContent">
      <!--            <h5 class="modal-title">Elfelejtett jelszó</h5>-->
      <p *ngIf="messageSuccess">
        Elfelejtetted jelszavad? Kérjük, add meg regisztrált e-mail címed. A
        válaszlevelünkben található link-re kattintva új Jelszót igényelhetsz.
      </p>
      <p *ngIf="success">Sikeres küldés</p>
      <div class="form-box">
        <form id="forgottenPwForm" [formGroup]="form">
          <fieldset *ngIf="!success">
            <div class="input-box">
              <input
                type="email"
                name="email"
                formControlName="email"
                placeholder="E-mail cím"
              />
            </div>
          </fieldset>
          <div
            *ngIf="message"
            class="errormessage"
            style="color: red !important"
          >
            <h2 style="padding-top: 5px; color: #454f63; margin-top: 15px">
              Hibás adatok!
            </h2>
            <p style="padding-top: 10px">{{ text }}</p>
          </div>
          <button
            *ngIf="!success"
            type="submit"
            class="cd-btn cd-btn--rounded cd-btn--green w-100 mt-4 mb-2"
            style="border-radius: 20px !important"
            (click)="onSubmit()"
          >
            Küldés
          </button>
          <button
            *ngIf="success"
            type="submit"
            class="cd-btn cd-btn--rounded cd-btn--green w-100 mt-4 mb-2"
            style="border-radius: 20px !important"
            (click)="onClose()"
          >
            OK
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-spinner color="#00b267" size="medium" type="ball-spin-clockwise-fade">
  <p style="font-size: 20px; color: white">Kérem, várjon!</p>
</ngx-spinner>
