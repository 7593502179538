<div class="container d-flex flex-column align-items-center" *transloco="let t; read: 'PAGE.PROFILE.FORM'">
  <div class="w-100 p-3 p-md-0">
    <h1 class="mb-5 fw-bold fs-2">{{ t('HEADER_TEXT')}}</h1>
    <nav class="cd-tabnav">
      <ul class="cd-tabnav__list">
        <li routerLinkActive="cd-tabnav__item--active" class="cd-tabnav__item">
          <a routerLink="/user/profile" class="cd-tabnav__link"
            >{{ t('PERSONAL_PAGE_TEXT') }}</a
          >
        </li>
        <li routerLinkActive="cd-tabnav__item--active" class="cd-tabnav__item">
          <a routerLink="/user/purchases" class="cd-tabnav__link"
            >{{ t('VOUCHER_PAGE_TEXT') }}</a
          >
        </li>
      </ul>
    </nav>
    <router-outlet></router-outlet>
  </div>
</div>
