import { Inject, Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { TOAST_CONFIG_TOKEN, ToastConfig, ToastData } from './toast-config';
import { ToastRef } from './toast-ref';
import { ToastComponent } from './toast.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class OldToastService {
  private lastToast: ToastRef | null = null;

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {
    // if( !this.toastConfig.position) {
    //     this.toastConfig.position
    // }
  }

  // openModal() {
  //   const dialogRef = this.dialog.open(SuccessfulRegisterComponent, {
  //     width: '500px',
  //   });
  // }

  show(data: ToastData) {
    this.close();
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef.attach(toastPortal);

    return toastRef;
  }

  close() {
    if (this.lastToast) {
      this.lastToast.close();
      this.lastToast = null;
    }
  }

  getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top(this.getPosition())
      .right(this.toastConfig?.position?.right + 'px');
  }

  getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast?.getPosition().bottom
      : this.toastConfig?.position?.right;

    return position + 'px';
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
