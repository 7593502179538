import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { environment } from '@citydeals/env';
import { AutoPopupEntity } from '../entity/auto-popup.entity';

@Component({
  selector: 'citydeals-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent {
  imageBaseUrl = environment.backendStorageBaseUrl;

  constructor(
    public dialogRef: MatDialogRef<PopupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AutoPopupEntity
  ) {}

  close() {
    this.dialogRef.close();
  }

  navigate() {
    window.open(this.data.cta_link);
    this.dialogRef.close();
  }
}
