import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extensionToWebp',
})
export class ExtensionToWebpPipe implements PipeTransform {
  transform(path?: string): string | null | undefined {
    if (path) {
      return `${path.substring(0, path.lastIndexOf('.'))}.webp`;
    }
    return path;
  }
}
