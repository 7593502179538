import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
import { EventPageType } from '../models/event-page-type.model';

export class PageInitEvent implements CitydealsEvent {
  static eventName = 'pageInit';
  private pageType: EventPageType;
  private userId: number | 'unknown';

  constructor(pageType: EventPageType, userId: number | null) {
    this.pageType = pageType;
    this.userId = userId ?? 'unknown';
  }

  getEvent(): AnalitycsEvent {
    return {
      event: PageInitEvent.eventName,
      pageType: this.pageType,
      userId: this.userId,
    };
  }
}
