import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { environment } from '@citydeals/env';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { PurchaseEntity } from '../../../entity/purchase.entity';
import { isNumeric } from '@citydeals/common';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'citydeals-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasesComponent {
  readonly imgURL = environment.backendStorageBaseUrl;
  readonly backendUrl = environment.backendUrl;
  readonly vouchers$: Observable<PurchaseEntity[]>;

  constructor(@Inject(PLATFORM_ID) platformId: string, http: HttpClient) {
    if (isPlatformBrowser(platformId)) {
      this.vouchers$ = http
        .get<{ data: PurchaseEntity[] }>(
          `${environment.backendUrl}/client/offers/index/bought`
        )
        .pipe(map((response) => response.data));
    } else {
      this.vouchers$ = of([]);
    }
  }

  trackByFn(_: number, voucher: PurchaseEntity) {
    return voucher.code;
  }

  getDiscount(value: string) {
    return isNumeric(value) ? +value : undefined;
  }
}
