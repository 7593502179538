import { NgModule } from '@angular/core';
import { FormControlErrorMessagesComponent } from './form-control-error-messages.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [FormControlErrorMessagesComponent],
  exports: [FormControlErrorMessagesComponent],
})
export class FormControlErrorMessagesModule {}
