import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreventClickDirective } from './prevent-click.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PreventClickDirective],
  exports: [PreventClickDirective],
})
export class PreventClickModule {}
