import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BookmarkStateModel } from './model/bookmark-state.model';
import { SetBookmarkAction } from './action/set-bookmark.action';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { RemoveBookmarkAction } from './action/remove-bookmark.action';
import { AuthState, LoginInModalAction, LogoutAction } from '@citydeals/auth';
import { VoucherEntity } from '@citydeals/category';
import { HttpClient } from '@angular/common/http';
import { environment } from '@citydeals/env';
import { catchError, filter, tap, throwError } from 'rxjs';
import { LoadBookmarkRemoteStateAction } from './action/load-bookmark-remote-state.action';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@State<BookmarkStateModel>({
  name: 'citydeals_bookmarks',
  defaults: { bookmarks: [] },
})
@Injectable()
export class BookmarkState implements NgxsOnInit {
  @Selector()
  static isBookmarked(state: BookmarkStateModel) {
    return (voucher: VoucherEntity): boolean => {
      return state.bookmarks.find((bv) => bv.id === voucher.id) !== undefined;
    };
  }

  @Selector()
  @ImmutableSelector()
  static bookmarks(state: BookmarkStateModel) {
    return state.bookmarks;
  }

  @Selector()
  @ImmutableSelector()
  static bookmarkCount(state: BookmarkStateModel) {
    return state.bookmarks.length;
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private matSnackBar: MatSnackBar,
    private router: Router
  ) {}

  ngxsOnInit(ctx?: StateContext<BookmarkState>) {
    this.store
      .select(AuthState.isLoggedIn)
      .pipe(filter((isLoggedIn) => isLoggedIn))
      .subscribe(() =>
        this.store.dispatch(new LoadBookmarkRemoteStateAction())
      );
  }

  @Action(SetBookmarkAction)
  @ImmutableContext()
  setBookmark(
    { setState, dispatch }: StateContext<BookmarkStateModel>,
    { voucher }: SetBookmarkAction
  ) {
    if (!this.store.selectSnapshot(AuthState.isLoggedIn)) {
      return dispatch(new LoginInModalAction(voucher));
    }

/*
    this.snackbarOk('Ajánlat mentése folyamatban');
*/
    return this.remoteToggleBookmark(voucher.offerId).pipe(
      tap((response) => {
        setState((state: BookmarkStateModel) => {
          state.bookmarks.push(voucher);
          return state;
        });
        this.snackbarOk('Angebot gespeichert');
      }),
      catchError((e) => {
        this.snackbarError('Ajánlat mentése közben hiba lépett fel');
        return throwError(e);
      })
    );
  }

  @Action(RemoveBookmarkAction)
  @ImmutableContext()
  removeBookmark(
    { setState }: StateContext<BookmarkStateModel>,
    { voucher }: SetBookmarkAction
  ) {
/*
    this.snackbarOk('Ajánlat törlése folyamatban');
*/
    return this.remoteToggleBookmark(voucher.offerId).pipe(
      tap((response) => {
        setState((state: BookmarkStateModel) => {
          state.bookmarks = state.bookmarks.filter(
            (bv) => bv.id !== voucher.id
          );
          return state;
        });
        this.snackbarOk('Ajánlat törölve');
      }),
      catchError((e) => {
        this.snackbarError('Ajánlat törlése közben hiba lépett fel');
        return throwError(e);
      })
    );
  }

  private snackbarOk(message: string, action?: string) {
    return this.openSnackBar(message, ['snackBarStyleForSave'], action);
  }

  private snackbarError(message: string, action?: string) {
    return this.openSnackBar(message, ['snackBarStyleForError'], action);
  }

  private openSnackBar(message: string, panelClass: string[], action?: string) {
    return this.matSnackBar.open(message, action, {
      panelClass,
      duration: 2000,
    });
  }

  @Action(LoadBookmarkRemoteStateAction)
  @ImmutableContext()
  loadBookmarkRemoteState({ setState }: StateContext<BookmarkStateModel>) {
    return this.http
      .get<{ data: VoucherEntity[] }>(
        `${environment.backendUrl}/client/offers/index/saved`
      )
      .pipe(
        tap((response) => {
          setState((state: BookmarkStateModel) => {
            state.bookmarks = response.data;
            return state;
          });
        })
      );
  }

  @Action(LogoutAction)
  @ImmutableContext()
  logout({ setState }: StateContext<BookmarkStateModel>) {
    setState((state: BookmarkStateModel) => {
      state.bookmarks = [];
      return state;
    });
  }

  private remoteToggleBookmark(voucherId: number) {
    return this.http.post(
      `${environment.backendUrl}/client/offers/toggle-saved/voucher`,
      {
        offerId: voucherId,
      }
    );
  }
}
