export class ResetPasswordAction {
  static type = '[AUTH] Reset Password';

  constructor(
    readonly payload: {
      email: string;
      token: string;
      password: string;
      passwordConfirm: string;
    }
  ) {}
}
