<div class="modal-header">
  <h5 class="modal-title modal-title-forgotten">{{ data.title }}</h5>
  <button
    style="margin-top: -0.3rem"
    mat-icon-button
    tabindex="-1"
    (click)="close()"
  >
    <mat-icon aria-label="Close dialog">close</mat-icon>
  </button>
</div>
<div class="modal-body" style="padding: 15px !important">
  <div class="forgottenPwContent">
    <img [src]="imageBaseUrl + data.image.path" class="centeredPopupImg" />
    <p
      [innerHTML]="data.text | sanitizeHtml"
      style="margin-top: 1rem"
      class="roliFormat"
    ></p>
    <div class="formBox">
      <form id="forgottenPwForm">
        <button
          class="cd-btn cd-btn--rounded cd-btn--green submitBtn"
          (click)="navigate()"
          style="
            width: auto;
            margin: auto;
            padding: 13px;
            border-radius: 20px !important;
          "
        >
          {{ data.cta }}
        </button>
      </form>
    </div>
  </div>
</div>
