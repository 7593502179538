import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { isBoolean, isNil } from '@citydeals/common';
import { Store } from '@ngxs/store';
import { LoginWithTokenAction } from '../../state/action/login-with-token.action';
import { ReloadUserDataAction } from '../../state/action/reload-user-data.action';
import { mapTo, Observable } from 'rxjs';

@Injectable()
export class AuthTokenGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean> {
    const token = route.queryParamMap.get('token');

    if (isNil(token)) {
      const redirectWithoutToken = isBoolean(route.data['redirectWithoutToken'])
        ? route.data['redirectWithoutToken']
        : false;
      return redirectWithoutToken ? this.router.parseUrl('/') : true;
    }

    const userType: 'CLIENT' | 'PARTNER' = route.data['userType'] ?? 'CLIENT';

    return this.store
      .dispatch([
        new LoginWithTokenAction(token, userType),
        new ReloadUserDataAction(),
      ])
      .pipe(mapTo(true));
  }
}
