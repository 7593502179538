import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frontend-auth-page',
  styleUrls: ['./auth-page.component.scss'],
  templateUrl: './auth-page.component.html',
})
export class AuthPageComponent implements OnInit {
  partner = false;
  constructor(private route: Router) {}

  ngOnInit() {
    const url = this.route.routerState.snapshot.url.split('/');
    if (url.includes('partner-registration')) {
      this.partner = true;
    } else {
      this.partner = false;
    }
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const newUrl = event.url.split('/');
        if (newUrl.includes('partner-registration')) {
          this.partner = true;
        } else {
          this.partner = false;
        }
      }
    });
  }
}
