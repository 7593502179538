import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxsModule } from '@ngxs/store';
import { AutoPopupState } from '@citydeals/feature/auto-popup';
import { PopupModalComponent } from './popup-modal/popup-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { SanitizePipeModule } from '@citydeals/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [PopupModalComponent],
  imports: [
    MatDialogModule,
    NgxsModule.forFeature([AutoPopupState]),
    MatButtonModule,
    MatIconModule,
    SanitizePipeModule,
  ],
  exports: [MatDialogModule],
})
export class AutoPopupModule {}
