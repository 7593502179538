import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';

import { AuthPageComponent } from './auth-page/auth-page.component';
import { AuthClientLoginComponent } from './auth-client-login/auth-client-login.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ForgottenPasswordModalComponent } from './forgotten-password-modal/forgotten-password-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthClientRegisterComponent } from './auth-client-register/auth-client-register.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthClientLoginModalComponent } from './auth-client-login-modal/auth-client-login-modal.component';
import { SuccessfulRegistrationComponent } from './modals/successful-registration/successful-registration.component';
import { UserActivateComponent } from './user-activate/user-activate.component';
import { ClientPasswordComponent } from './client-password/client-password.component';
import {FormControlErrorMessagesModule, MobileDetectModule} from '@citydeals/common';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    AuthPageComponent,
    AuthClientRegisterComponent,
    AuthClientLoginComponent,
    // AuthPartnerRegisterComponent,
    UserActivateComponent,
    // AdminPasswordComponent,
    ClientPasswordComponent,
    ForgottenPasswordModalComponent,
    AuthClientLoginModalComponent,
    SuccessfulRegistrationComponent,
  ],
  imports: [
    TranslocoModule,
    CommonModule,
    AuthenticationRoutingModule,
    MatFormFieldModule,
    MatRadioModule,
    MatButtonModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatIconModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    FormControlErrorMessagesModule,
    MobileDetectModule,
  ],
  exports: [AuthClientLoginModalComponent],
})
export class AuthenticationModule {}
