<div class="login-and-reg-page-content" [ngClass]="{ partner: partner }">
  <figure class="title-box">
    <a routerLink="/"
      ><img src="/assets/img/foxydeals_logo.png" alt="Foxydeals logo"
    /></a>
    <img src="https://picsum.photos/id/155/600/1080" alt="" />
    <h2></h2>
  </figure>
  <div class="main-content" [ngClass]="{ partnerReg: partner }">
    <router-outlet></router-outlet>
  </div>
</div>
