import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
import { EventProduct } from '../models/event-product.model';
import { PageInitEvent } from './page-init.event';

export class ResultsPageEvent extends PageInitEvent implements CitydealsEvent {
  private products: EventProduct[];

  constructor(userId: number | null, products: EventProduct[]) {
    super('resultspage', userId);
    this.products = products;
  }

  override getEvent(): AnalitycsEvent {
    return {
      ...super.getEvent(),
      ecommerce: {
        impressions: this.products,
      },
    };
  }
}
