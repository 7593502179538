import { Action, NgxsOnInit, State, StateContext } from '@ngxs/store';
import {Inject, Injectable, NgZone, Optional, PLATFORM_ID} from '@angular/core';
import { AutoPopupStateModel } from './model/auto-popup-state.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@citydeals/env';
import { catchError, filter, of, timer } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutoPopupEntity } from '../entity/auto-popup.entity';
import { ShowAutoPopupsAction } from './action/show-auto-popups.action';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { MatDialog } from '@angular/material/dialog';
import { PopupModalComponent } from '../popup-modal/popup-modal.component';
import { isNil, IS_BOT } from '@citydeals/common';
import { isPlatformBrowser } from '@angular/common';

export const autopopupStateName = 'citydeals_auto_popup';

@UntilDestroy()
@State<AutoPopupStateModel>({
  name: autopopupStateName,
  defaults: { popups: [] },
})
@Injectable()
export class AutoPopupState implements NgxsOnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone,
    private matDialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() @Inject(IS_BOT) private readonly isBot = false
  ) {}

  ngxsOnInit(ctx?: StateContext<AutoPopupStateModel>) {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e: unknown) => e instanceof NavigationEnd),
        filter(() => isPlatformBrowser(this.platformId) || this.isBot === true)
      )
      .subscribe((event: unknown) => {
        const url = (event as NavigationEnd).urlAfterRedirects;
        let categoryName = '';
        if (url === '/') {
          categoryName = 'home';
        }
        if (url.startsWith('/kategoriak/')) {
          categoryName = url.split('/').pop() ?? '';
        }
        this.http
          .get<{ data: AutoPopupEntity[] }>(
            `${environment.backendUrl}/client/pup/${categoryName}`
          )
          .pipe(catchError(() => of({ data: null })))
          .subscribe(({ data }) => {
            if (isNil(data)) {
              return;
            }
            const visitedIds = (
              ctx?.getState()?.popups ?? ([] as AutoPopupEntity[])
            ).map((p) => p.id);
            const needShow = data.filter(
              (d) => visitedIds.indexOf(d.id) === -1
            );
            if (needShow.length > 0) {
              ctx?.dispatch(new ShowAutoPopupsAction(needShow));
            }
            ctx?.setState({ popups: data } as any);
          });
      });
  }

  @Action(ShowAutoPopupsAction)
  @ImmutableContext()
  showAutoPopups(
    ctx: StateContext<AutoPopupStateModel>,
    { popups }: ShowAutoPopupsAction
  ) {
    this.ngZone.run(() => {
      popups.forEach((element) => {
        timer(element.timing * 1000).subscribe(() => {
          this.matDialog.open(PopupModalComponent, {
            width: '800px',
            hasBackdrop: false,
            data: element,
          });
        });
      });
    });
  }
}
