import { Pipe, PipeTransform } from '@angular/core';
import { MobileDetectService } from './mobile-detect.service';

@Pipe({
  name: 'isMobileDevice',
})
export class IsMobileDevicePipe implements PipeTransform {
  constructor(private mobileDetectService: MobileDetectService) {}

  transform(value: unknown) {
    return (
      this.mobileDetectService.isMobile() !== null ||
      this.mobileDetectService.isTablet() !== null
    );
  }
}
