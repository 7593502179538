import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isNil } from '../../typeguard/is-nil';

@Pipe({
  name: 'sanitizeHtml',
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | null | undefined): any {
    if (isNil(value)) {
      return '';
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
