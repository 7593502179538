import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
import { EventBanner } from '../models/event-banner.model';

export class BannerEvent implements CitydealsEvent {
  static eventName = 'banner';
  private banners: EventBanner[];

  constructor(banners: EventBanner[]) {
    this.banners = banners;
  }

  getEvent(): AnalitycsEvent {
    return {
      event: BannerEvent.eventName,
      ecommerce: {
        promoView: {
          promotions: this.banners,
        },
      },
    };
  }
}
