import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

export const REDIRECT_404 = new HttpContextToken<boolean>(() => false);

@Injectable()
export class Interceptor404 implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.context.get(REDIRECT_404) === true) {
      return next.handle(req).pipe(
        catchError((error) => {
          this.router.navigate(['/404-not-found']);
          return throwError(error);
        })
      );
    }
    return next.handle(req);
  }
}
