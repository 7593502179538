import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@citydeals/env';
import { isString } from '@citydeals/common';

function getQueryParams(paramName?: string) {
  const params = window.location.search
    .split('?')[1]
    .split('&')
    .reduce((acc, next) => {
      if (isString(next) && next.length > 0) {
        const splttedValue = next.split('=');
        acc[splttedValue[0]] = splttedValue[1];
      }
      return acc;
    }, {} as Record<string, string>);
  if (isString(paramName) && paramName.length > 0) {
    return params[paramName];
  }
  return params;
}

function loadCookieDeclaration() {
  const cookieDeclarationScript = document.createElement('script');
  cookieDeclarationScript.src = 'https://consent.cookiebot.com/e4676182-ee7c-4888-b6f0-31563094dc59/cd.js';
  cookieDeclarationScript.id = 'CookieDeclaration';
  cookieDeclarationScript.type = 'text/javascript';
  cookieDeclarationScript.defer = true;
  cookieDeclarationScript.async = true;

  cookieDeclarationScript.onload = () => {
    console.log('CookieDeclaration Script loaded.');
  };

  cookieDeclarationScript.onerror = () => {
    console.error('Could not load the Cookiebot Script!');
  };
  document.head.appendChild(cookieDeclarationScript);
}

if (environment.production) {

  const cookiebotScript = document.createElement('script');
  cookiebotScript.src = 'https://consent.cookiebot.com/uc.js';
  cookiebotScript.id = 'Cookiebot';

  cookiebotScript.setAttribute("data-culture", "DE");
  cookiebotScript.setAttribute("data-cbid", "e4676182-ee7c-4888-b6f0-31563094dc59");
  //cookiebotScript.setAttribute("data-blockingmode", "auto");

  cookiebotScript.type = 'text/javascript';
  cookiebotScript.defer = true;
  cookiebotScript.async = true;

  cookiebotScript.onload = () => {
    console.log('Cookiebot Script loaded.');
    loadCookieDeclaration();
  };

  cookiebotScript.onerror = () => {
    console.error('Could not load the Cookiebot Script!');
  };
  document.head.appendChild(cookiebotScript);

}

if (isString(window.location.search) && window.location.search.length > 0) {
  const token = getQueryParams('magic_link_token');
  if (isString(token) && token.length > 0) {
    localStorage.clear();
    localStorage.setItem(
      'citydeals_auth',
      JSON.stringify({
        isLoggedIn: true,
        type: 'CLIENT',
        token,
        user: null,
      })
    );

    window.location.replace(window.location.pathname);
  }
}

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
