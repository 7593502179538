import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CitydealsFormGroup } from '../form-group/form-group';
import { FormControlErrorMessageTypeModel } from './form-control-error-message-type.model';

@Component({
  selector: 'citydeals-form-control-error-messages',
  templateUrl: './form-control-error-messages.component.html',
  styleUrls: ['./form-control-error-messages.component.scss'],
})
export class FormControlErrorMessagesComponent {
  @Input() messageTypes!: FormControlErrorMessageTypeModel[];
  @Input() control!: FormControl;
  @Input() parentFormGroup!: CitydealsFormGroup;
}
