import { InjectionToken, TemplateRef } from '@angular/core';

export class ToastData {
  type!: ToastType;
  text?: string | string[];
  template?: TemplateRef<any>;
  templateContext?: any;
}

export type ToastType = 'warning' | 'info' | 'success';

export interface ToastConfig {
  position?: {
    bottom: number;
    // top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
    bottom: 20,
    right: 20,
  },
  animation: {
    fadeOut: 2500,
    fadeIn: 300,
  },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
