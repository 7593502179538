import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ForgottenPasswordModalComponent } from '../forgotten-password-modal/forgotten-password-modal.component';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { LoginAction } from '../../state/action/login.action';
import { SocialLoginAction } from '../../state/action/social-login.action';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@citydeals/env';
import { ReloadUserDataAction } from '../../state/action/reload-user-data.action';
import { LoginWithTokenAction } from '../../state/action/login-with-token.action';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frontend-auth-client-login',
  templateUrl: './auth-client-login.component.html',
  styleUrls: [
    '../../../../../styles/src/lib/layouts/auth.common.scss',
    './auth-client-login.component.scss',
  ],
})
export class AuthClientLoginComponent implements OnInit {
  hide = true;
  clientLoginForm = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    ),
    password: new FormControl('', Validators.required),
    stayLoggedIn: new FormControl(false),
  });
  partnerUrl = environment.partnerAdminUrl;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private actions$: Actions,
    private matSnackBar: MatSnackBar
  ) {
    this.actions$
      .pipe(ofActionSuccessful(LoginAction), untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token');
    const error = this.route.snapshot.queryParamMap.get('error');
    if (token) {
      this.store.dispatch([
        new LoginWithTokenAction(token),
        new ReloadUserDataAction(),
      ]);
      this.router.navigate(['/']);
    }
    if (error) {
      this.matSnackBar.open('Hiba a bejelentkezés során', undefined, {
        duration: 2000,
        panelClass: ['snackBarStyleForError'],
      });
    }
  }

  onSubmit() {
    const formData = this.clientLoginForm.value;
    const credentials = {
      email: formData.email,
      password: formData.password,
    };

    this.store.dispatch(new LoginAction(credentials, 'CLIENT'));
    // this.store.dispatch(
    //   AuthActions.userLogin({
    //     credentials,
    //     userType: 'CLIENT',
    //     loginType: 'SITE',
    //   })
    // );
    // this.router.navigate(['/']);
  }

  openModal() {
    this.dialog.open(ForgottenPasswordModalComponent, {
      width: '500px',
    });
  }

  socialLogin(provider: string) {
    this.store.dispatch(new SocialLoginAction(provider)).subscribe((res) => {
      window.location.href = res.data.redirect;
    });
    // this.authService.socialLogin(provider).subscribe((res) => {
    //   window.location.href = res.data.redirect;
    // });
  }
}
