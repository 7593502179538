import { ModuleWithProviders, NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';

import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-config';
import { ToastComponent } from './toast.component';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [OverlayModule, MatIconModule, CommonModule, MatDialogModule],
  declarations: [ToastComponent],
  entryComponents: [ToastComponent],
})
export class OldToastModule {
  public static forRoot(
    config = defaultToastConfig
  ): ModuleWithProviders<OldToastModule> {
    return {
      ngModule: OldToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
      ],
    };
  }
}
