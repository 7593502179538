import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserRoutingModule } from './user-routing.module';
import { WrapperComponent } from './wrapper/wrapper.component';
import { ProfileComponent } from './profile/profile.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SuccessfulPasswordModule } from '../../layout/modals/successful-password/successful-password.module';
import {
  CardImageModule,
  FormControlErrorMessagesModule,
  MobileDetectModule,
} from '@citydeals/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  declarations: [WrapperComponent, ProfileComponent, PurchasesComponent],
  imports: [
    CommonModule,
    UserRoutingModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SuccessfulPasswordModule,
    FormControlErrorMessagesModule,
    MatIconModule,
    MatCheckboxModule,
    MobileDetectModule,
    CardImageModule,
    TranslocoModule
  ],
})
export class UserModule {}
