<div>
  <form id="profileForm" [formGroup]="profileForm" (submit)="updateProfile()" *transloco="let t; read: 'PAGE'">
    <fieldset class="mb-5">
      <div class="d-flex flex-column flex-sm-row mb-3">
        <div class="flex-grow-1 px-1 mb-3 mb-sm-0">
          <label for="firstName" class="cd-label">{{ t('COMMON.FIRST_NAME') }}</label>
          <input
            type="text"
            name="firstName"
            formControlName="firstName"
            class="cd-input profile-form__input"
          />
        </div>
        <div class="flex-grow-1 px-1">
          <label for="lastName" class="cd-label">{{ t('COMMON.LAST_NAME') }}</label>
          <input
            type="text"
            name="lastName"
            formControlName="lastName"
            class="cd-input profile-form__input"
          />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="flex-grow-1 px-1">
          <label for="birthdate" class="cd-label">{{ t('REGISTRATION.FORM.BIRTH_DATE') }}</label>
          <div class="position-relative">
            <input
              type="text"
              name="birthdate"
              formControlName="birthdate"
              class="cd-input profile-form__input"
              [matDatepicker]="picker"
              (click)="picker.open()"
            />
            <i
              class="icon icon-calendar profile-form__suffix"
              (click)="picker.open()"
            ></i>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="flex-grow-1 px-1">
          <label for="email" class="cd-label">{{ t('COMMON.EMAIL_ADDRESS') }}</label>
          <input
            type="email"
            name="email"
            [formControl]="emailControl"
            class="cd-input profile-form__input"
          />
          <citydeals-form-control-error-messages
            class="mt-2"
            [parentFormGroup]="profileForm"
            [control]="emailControl"
            [messageTypes]="[
              { type: 'required', msg:  t('VALIDATION_TEXT.REQUIRED') },
              { type: 'email', msg:  t('VALIDATION_TEXT.INVALID_EMAIL_FORMAT') }
            ]"
          >
          </citydeals-form-control-error-messages>
        </div>
      </div>
      <div class="d-flex">
        <label class="cd-label flex-grow-1 px-1">{{ t('COMMON.ADDRESS_DATA') }}</label>
      </div>
      <div class="d-flex flex-column flex-sm-row mb-3">
        <div class="flex-grow-1 px-1 mb-3 mb-sm-0">
          <input
            type="text"
            name="zipcode"
            placeholder="{{ t('REGISTRATION.FORM.ZIPCODE') }}"
            formControlName="zipcode"
            class="cd-input profile-form__input"
          />
        </div>
        <div class="flex-grow-1 px-1">
          <input
            type="text"
            name="settlement"
            placeholder="{{ t('REGISTRATION.FORM.SETTLEMENT') }}"
            formControlName="settlement"
            class="cd-input profile-form__input"
          />
        </div>
      </div>
      <div class="d-flex flex-column flex-sm-row">
        <div class="flex-grow-1 px-1 mb-3 mb-sm-0">
          <input
            type="text"
            name="addressName"
            placeholder="{{ t('REGISTRATION.FORM.ADDRESS') }}"
            formControlName="addressName"
            class="cd-input profile-form__input"
          />
        </div>
        <div class="flex-grow-1 px-1">
          <input
            type="text"
            name="addressNum"
            placeholder="{{ t('REGISTRATION.FORM.ADDRESS_NUM') }}"
            formControlName="addressNum"
            class="cd-input profile-form__input"
          />
        </div>
      </div>
    </fieldset>
    <legend class="cd-legend">{{ t('PROFILE.FORM.CHANGE_PASSWORD_TEXT') }}</legend>
    <fieldset class="mb-5">
      <div class="d-flex flex-column">
        <div class="flex-grow-1 px-1 mb-3">
          <label for="oldPassword" class="cd-label">{{ t('PROFILE.FORM.CURRENT_PASSWORD_TEXT') }}</label>
          <div class="position-relative">
            <input
              [type]="hidePassword ? 'password' : 'text'"
              name="oldPassword"
              [formControl]="oldPasswordControl"
              class="cd-input profile-form__input"
            />
            <mat-icon
              (click)="hidePassword = !hidePassword"
              class="profile-form__suffix"
              >{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon
            >
          </div>
        </div>
        <div class="flex-grow-1 px-1 mb-3">
          <label for="newPassword" class="cd-label">{{ t('PROFILE.FORM.NEW_PASSWORD_TEXT') }}</label>
          <div class="position-relative">
            <input
              [type]="hidePassword ? 'password' : 'text'"
              name="newPassword"
              [formControl]="newPasswordControl"
              class="cd-input profile-form__input"
            />
            <mat-icon
              (click)="hidePassword = !hidePassword"
              class="profile-form__suffix"
              >{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon
            >
          </div>
          <citydeals-form-control-error-messages
            class="mt-2"
            [parentFormGroup]="profileForm"
            [control]="newPasswordControl"
            [messageTypes]="[
              {
                type: 'pattern',
                msg: t('VALIDATION_TEXT.INVALID_PASSWORD_FORMAT')
              }
            ]"
          >
          </citydeals-form-control-error-messages>
        </div>
        <div class="flex-grow-1 px-1">
          <label for="newPasswordConfirm" class="cd-label"
            >{{ t('PROFILE.FORM.NEW_PASSWORD_CONFIRM_TEXT') }}</label
          >
          <div class="position-relative">
            <input
              [type]="hidePassword ? 'password' : 'text'"
              name="newPasswordConfirm"
              [formControl]="newPasswordConfirmControl"
              class="cd-input profile-form__input"
            />
            <mat-icon
              (click)="hidePassword = !hidePassword"
              class="profile-form__suffix"
              >{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon
            >
          </div>
          <citydeals-form-control-error-messages
            class="mt-2"
            [parentFormGroup]="profileForm"
            [control]="newPasswordConfirmControl"
            [messageTypes]="[
              { type: 'mismatch', msg: t('VALIDATION_TEXT.PASSWORDS_NOT_MATCH') }
            ]"
          >
          </citydeals-form-control-error-messages>
        </div>
      </div>
    </fieldset>
    <fieldset class="mb-5">
      <div class="px-1">
        <mat-checkbox formControlName="newscheck" class="cd-checkbox">
          {{ t('REGISTRATION.FORM.SUBSCRIBE_NEWSLETTER') }}
        </mat-checkbox>
      </div>
    </fieldset>
    <div class="d-flex flex-column flex-sm-row justify-content-between">
      <div class="mb-3 mb-sm-0">
        <a
          routerLink="/kontakt"
          class="cd-btn cd-btn--rounded cd-btn--hover-pink w-100 w-sm-auto"
          title="{{ t('PROFILE.FORM.DELETE_USER_ACCOUNT_BUTTON_HINT') }}"
          >{{ t('PROFILE.FORM.DELETE_USER_ACCOUNT_BUTTON_TEXT') }}</a
        >
      </div>
      <div>
        <button
          type="submit"
          class="cd-btn cd-btn--green cd-btn--rounded w-100 w-sm-auto"
        >
          {{ t('PROFILE.FORM.SAVE_BUTTON_TEXT') }}
        </button>
      </div>
    </div>
  </form>
</div>
