import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@citydeals/env';

@Injectable()
export class UserPasswordService {
  constructor(private httpClient: HttpClient) {}
  activateUser(token: any): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.backendUrl}/client/activate/${token}`
    );
  }
}
