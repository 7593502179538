import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthPageComponent } from './auth-page/auth-page.component';
import { AuthClientLoginComponent } from './auth-client-login/auth-client-login.component';
import { AuthClientRegisterComponent } from './auth-client-register/auth-client-register.component';
import { NotShowToAuthedUserGuard } from '../not-show-to-authed-user.guard';
import { UserActivateComponent } from './user-activate/user-activate.component';
import { ClientPasswordComponent } from './client-password/client-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthPageComponent,
    children: [
      {
        path: 'registration',
        component: AuthClientRegisterComponent,
        canActivate: [NotShowToAuthedUserGuard],
      },
      {
        path: 'login',
        component: AuthClientLoginComponent,
        canActivate: [NotShowToAuthedUserGuard],
      },
      // { path: 'partner-registration', component: AuthPartnerRegisterComponent },
      { path: 'client/activate/:id', component: UserActivateComponent },
      // { path: 'set-partner-password/:id', component: AdminPasswordComponent },
      { path: 'set-password/:id', component: ClientPasswordComponent },
      { path: ':provider/login', component: AuthClientLoginComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
