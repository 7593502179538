import { UserType } from '../../old-auth.service';

export class LoginAction {
  static type = '[AUTH] Login';

  constructor(
    readonly payload: { email: string; password: string },
    readonly userType: UserType
  ) {}
}
