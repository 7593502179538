import { AnalitycsEvent } from '../models/analytics-event.model';
import { CitydealsEvent } from '../models/citydeals-event.model';
import { EventProduct } from '../models/event-product.model';
import { PageInitEvent } from './page-init.event';

export class ThankYouEvent extends PageInitEvent implements CitydealsEvent {
  private customerEmail: string;
  private payRef: string;
  private revenue: number;
  private products: EventProduct[];

  constructor(
    userId: number | null,
    customerEmail: string,
    payRef: string,
    revenue: number,
    products: EventProduct[]
  ) {
    super('thankyou', userId);
    this.customerEmail = customerEmail;
    this.payRef = payRef;
    this.revenue = revenue;
    this.products = products;
  }

  override getEvent(): AnalitycsEvent {
    return {
      ...super.getEvent(),
      customerEmail: this.customerEmail,
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: this.payRef,
            affiliation: '',
            revenue: this.revenue,
            tax: '',
            shipping: 0,
            coupon: '',
          },
          products: this.products,
        },
      },
    };
  }
}
