<div class="py-5">
  <h1 class="text-center form-box mb-4">Neues Passwort erstellen</h1>

  <p>
    Neues Passwort eingeben und anschließend auf 'Passwort erstellen' klicken.
  </p>
  <p>
    Das Passwort soll mindestens 8 Zeichen lang sein und sowohl Großbuchstaben, Kleinbuchstaben als auch Zahlen enthalten.
  </p>

  <form id="clientPasswordForm" [formGroup]="form" (submit)="resetPassword()">
    <div class="form-box">
      <fieldset>
        <div class="input-box">
          <input
            type="email"
            name="email"
            placeholder="E-mail cím"
            [formControl]="emailControl"
            class="monster"
          />
          <citydeals-form-control-error-messages
            class="mt-2"
            [parentFormGroup]="form"
            [control]="emailControl"
            [messageTypes]="[
              { type: 'required', msg: 'A mező megadása kötelező' },
              { type: 'email', msg: 'Hibás e-mail formátum' }
            ]"
          >
          </citydeals-form-control-error-messages>
        </div>
        <div class="input-box">
          <input
            [type]="hide ? 'password' : 'text'"
            name="password"
            placeholder="Jelszó"
            [formControl]="passwordControl"
            class="monster"
          />
          <mat-icon matSuffix (click)="hide = !hide" class="eye-class">{{
            hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <citydeals-form-control-error-messages
            class="mt-2"
            [parentFormGroup]="form"
            [control]="passwordControl"
            [messageTypes]="[
              { type: 'required', msg: 'A mező megadása kötelező' },
              {
                type: 'pattern',
                msg: 'A jelszó legalább 8 karaktert tartalmaz, közte nagybetűt, kisbetűt és számot is!'
              }
            ]"
          >
          </citydeals-form-control-error-messages>
        </div>
        <div class="input-box">
          <input
            [type]="hide ? 'password' : 'text'"
            name="password"
            placeholder="Jelszó megerősítése"
            [formControl]="passwordConfirmControl"
            class="monster"
          />
          <mat-icon matSuffix (click)="hide = !hide" class="eye-class">{{
            hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
          <citydeals-form-control-error-messages
            class="mt-2"
            [parentFormGroup]="form"
            [control]="passwordConfirmControl"
            [messageTypes]="[
              { type: 'required', msg: 'A mező megadása kötelező' },
              { type: 'mismatch', msg: 'A két Jelszó nem azonos!' }
            ]"
          >
          </citydeals-form-control-error-messages>
        </div>
      </fieldset>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <button
        type="submit"
        [disabled]="form.invalid"
        class="cd-btn cd-btn--green cd-btn--rounded"
      >
      Passwort erstellen
      </button>
    </div>
  </form>
</div>
