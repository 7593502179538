import { Environment } from './environment.interface';
import { version } from './version';

export const environment: Environment = {
  production: true,
  version: version,
  appUrl: 'https://dev.foxydeals.at',
  extraPlugins: [],
  backendUrl: 'https://api.dev.foxydeals.at/api',
  backendStorageBaseUrl: '',
  partnerAdminUrl: 'https://partneradmin.foxydeals.at',
  backendBucketStorageBaseUrl: 'https://storage.googleapis.com/foxydeals-images/public',
  recaptchaSiteKey: '6LfWa5okAAAAABtqHvhjW-B4AtfHPMF02rh4IA3X',
  trustTokenMainPageURL: 'https://cdn.trustindex.io/loader.js?861cd9c162b3420fc39667f5e8a',
  trustTokenCategoryURL: 'https://cdn.trustindex.io/loader.js?861cd9c162b3420fc39667f5e8a',
  trustTokenBasketURL: 'https://cdn.trustindex.io/loader.js?861cd9c162b3420fc39667f5e8a',
  hereApiKey: '8XStj-ZuGpAppIFby-l_3P6kIybmUGpuDpQZ2RHN-5E'
};
