import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import { environment } from '@citydeals/env';
import { GetCategoriesAction } from './action/get-categories.action';
import { tap } from 'rxjs';
import { ImmutableContext, ImmutableSelector } from '@ngxs-labs/immer-adapter';
import { CategoryEntity } from '../entity/category.entity';
import { SubCategoryEntity } from '../entity/sub-category.entity';
import { makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { IS_BOT } from '@citydeals/common';

interface CategoryStateModel {
  categoryList: CategoryEntity[] | null;
  loadingCategoryList: boolean;
}
const TRANSFER_STATE_KEY = makeStateKey<CategoryStateModel>(
  'state__citydeals_category'
);

const defaultState = { loadingCategoryList: true, categoryList: null };

@State<CategoryStateModel>({
  name: 'citydeals_category',
  defaults: defaultState,
})
@Injectable()
export class CategoryState implements NgxsOnInit {
  @Selector()
  @ImmutableSelector()
  static loadingCategoryList(state: CategoryStateModel): boolean {
    return state.loadingCategoryList;
  }

  @Selector()
  @ImmutableSelector()
  static categoryList(state: CategoryStateModel): any[] {
    return state.categoryList ?? [];
  }

  @Selector()
  static subCategoryListBySLug(state: CategoryStateModel) {
    return (slug: string): SubCategoryEntity[] | null => {
      return (
        (state.categoryList ?? []).find((category) => category.slug === slug)
          ?.subcategory ?? null
      );
    };
  }

  @Selector()
  static categoryBySlug(state: CategoryStateModel) {
    return (slug: string): CategoryEntity | null => {
      return (
        (state.categoryList ?? []).find((category) => category.slug === slug) ??
        null
      );
    };
  }

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private readonly platformId: string,
    @Optional() @Inject(IS_BOT) private readonly isBot = false
  ) {}

  ngxsOnInit(ctx: StateContext<CategoryStateModel>) {
    if (
      isPlatformBrowser(this.platformId) ||
      (isPlatformServer(this.platformId) && this.isBot === true)
    ) {
      // simple load
      ctx.dispatch(new GetCategoriesAction());
    }
  }

  @Action(GetCategoriesAction)
  @ImmutableContext()
  getCategories({ setState, getState }: StateContext<CategoryStateModel>) {
    setState((state: CategoryStateModel) => {
      state.loadingCategoryList = true;
      return state;
    });

    return this.http
      .get<{ data: CategoryEntity[] }>(
        `${environment.backendUrl}/client/categorylist`
      )
      .pipe(
        tap((response) => {
          setState((state: CategoryStateModel) => {
            state.categoryList = response.data;
            state.loadingCategoryList = false;
            return state;
          });
        })
      );
  }
}
