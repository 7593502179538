<div class="d-flex justify-content-between align-items-start">
  <h5 mat-dialog-title>Erfolgreiche Registrierung</h5>
  <button class="cd-btn--no-style" mat-dialog-close>
    <i class="icon icon-close"></i>
  </button>
</div>
<mat-dialog-content>
  <p>
    Sie haben sich erfolgreich registriert. Um Ihre Registrierung abzuschließen, öffnen Sie bitte Ihr E-Mail-Postfach und bestätigen Sie Ihre Mailadresse. Wenn Sie nicht bald eine E-Mail von uns erhalten, überprüfen Sie bitte Ihr "Spam"-Postfach oder ändern Sie die "Spam"-Einstellungen.
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="cd-btn cd-btn--green cd-btn-rounded w-100" mat-dialog-close>
    OK
  </button>
</mat-dialog-actions>
