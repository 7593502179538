import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@citydeals/env';

@Injectable()
export class ForgottenService {
  constructor(private httpClient: HttpClient) {}

  sendEmail(formdata: any) {
    return this.httpClient.post<any>(
      `${environment.backendUrl}/password/create`,
      formdata,
      {
        observe: 'response',
      }
    );
  }
}
