import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'citydeals-successful-registration',
  templateUrl: './successful-registration.component.html',
})
export class SuccessfulRegistrationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
