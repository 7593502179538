import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuccessfulPasswordComponent } from './successful-password.component';
import { NgxsModule } from '@ngxs/store';

@NgModule({
  declarations: [SuccessfulPasswordComponent],
  imports: [CommonModule, NgxsModule],
  exports: [SuccessfulPasswordComponent],
})
export class SuccessfulPasswordModule {}
