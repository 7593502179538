import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  CardImageModule,
  FormControlErrorMessagesModule,
  GetVoucherTitlePipeModule,
  PreventClickModule,
} from '@citydeals/common';
import { BookmarkVoucherComponent } from './bookmark-voucher/bookmark-voucher.component';
import { BookmarkPanelComponent } from './bookmark-panel/bookmark-panel.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from "@ngneat/transloco";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CardImageModule,
    GetVoucherTitlePipeModule,
    PreventClickModule,
    ReactiveFormsModule,
    FormControlErrorMessagesModule,
    TranslocoModule
  ],
  declarations: [BookmarkVoucherComponent, BookmarkPanelComponent],
  exports: [BookmarkPanelComponent],
})
export class BookmarkModule {}
