import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavUserComponent } from './top-nav-user.component';
import { RouterModule } from '@angular/router';
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
  declarations: [TopNavUserComponent],
  imports: [CommonModule, RouterModule, TranslocoModule],
  exports: [TopNavUserComponent],
})
export class TopNavUserModule {}
