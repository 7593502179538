import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CitydealsFormGroup, matchValuesValidator } from '@citydeals/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { filter, Observable } from 'rxjs';
import { UserEntity } from '../../../entity/user.entity';
import { ReloadUserDataAction } from '../../../state/action/reload-user-data.action';
import { UpdateUserAction } from '../../../state/action/update-user.action';
import { AuthState } from '../../../state/auth.state';

@UntilDestroy()
@Component({
  selector: 'citydeals-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit {
  @Select(AuthState.currentUser) currentUser$!: Observable<UserEntity | null>;

  emailControl = new FormControl(
    '',
    Validators.compose([Validators.required, Validators.email])
  );
  oldPasswordControl = new FormControl('');
  newPasswordControl = new FormControl(
    '',
    Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$')
  );
  newPasswordConfirmControl = new FormControl(
    '',
    matchValuesValidator('newPassword')
  );

  profileForm = new CitydealsFormGroup({
    firstName: new FormControl({ value: '', disabled: true }),
    lastName: new FormControl({ value: '', disabled: true }),
    birthdate: new FormControl(''),
    email: this.emailControl,
    addressName: new FormControl(''),
    addressType: new FormControl(''),
    addressNum: new FormControl(''),
    settlement: new FormControl(''),
    zipcode: new FormControl(''),
    country: new FormControl('Magyarország'),
    newscheck: new FormControl(false),
    oldPassword: this.oldPasswordControl,
    newPassword: this.newPasswordControl,
    newPasswordConfirm: this.newPasswordConfirmControl,
  });

  hidePassword = true;

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) private locale: string,
    private actions$: Actions
  ) {
    this.actions$
      .pipe(ofActionSuccessful(UpdateUserAction), untilDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new ReloadUserDataAction());
      });
  }

  ngOnInit(): void {
    this.currentUser$
      .pipe(
        filter((user) => !!user),
        untilDestroyed(this)
      )
      .subscribe((user) => {
        this.profileForm.patchValue({
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          addressName: user?.addressName,
          addressType: user?.addressType,
          addressNum: user?.addressNum,
          settlement: user?.settlement,
          zipcode: user?.zipcode,
          birthdate: user?.birthdate,
          newscheck: user?.newsCheck,
        });
      });
  }

  updateProfile() {
    if (this.profileForm.invalid) {
      return;
    }

    const formattedBirthDate = this.profileForm.value['birthdate']
      ? formatDate(
          this.profileForm.value['birthdate'],
          'YYYY-MM-dd',
          this.locale
        )
      : '';

    this.store.dispatch(
      new UpdateUserAction({
        ...this.profileForm.getRawValue(),
        birthdate: formattedBirthDate,
      })
    );
    this.oldPasswordControl.patchValue('');
    this.newPasswordControl.patchValue('');
    this.newPasswordConfirmControl.patchValue('');
  }
}
