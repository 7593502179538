import {DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, NgModule, PLATFORM_ID} from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
  DomSanitizer,
} from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgxsModule } from '@ngxs/store';
import { BasketState, basketStateName } from '@citydeals/basket';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthInterceptor, AuthState, authStateName } from '@citydeals/auth';
import { environment } from '@citydeals/env';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { isPlatformServer, registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgxAwesomePopupModule,
  ToastNotificationConfigModule,
} from '@costlydeveloper/ngx-awesome-popup';
import { CategoryState } from '@citydeals/category';
import { OldToastModule } from '@citydeals/feature/old-toast';
import { MatIconRegistry } from '@angular/material/icon';
import { BookmarkState } from '@citydeals/bookmark';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
/*
import {
  NgcCookieConsentModule,
  NgcCookieConsentService,
} from 'ngx-cookieconsent';
import { cookieConsentConfig } from './cookie-consent-constant';
*/
import {
  AutoPopupModule,
  autopopupStateName,
} from '@citydeals/feature/auto-popup';
import { debugLog, Interceptor404 } from '@citydeals/common';
import { TranslocoRootModule } from './transloco-root.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take, tap } from 'rxjs';
import { SeoSocialShareData, SeoSocialShareService } from 'ngx-seo';
import {MAT_DATE_LOCALE} from "@angular/material/core";
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'citydeals' }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    HttpClientModule,
    AppRoutingModule,
    TranslocoRootModule,
    NgxsModule.forRoot([AuthState, BasketState, CategoryState, BookmarkState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [authStateName, basketStateName, autopopupStateName],
    }),
    AutoPopupModule,
/*
    NgcCookieConsentModule.forRoot(cookieConsentConfig),
*/
    SvgIconsModule.forRoot(),
    NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
    ToastNotificationConfigModule.forRoot(), // Needed for instantiating toast notifications.
    OldToastModule.forRoot(),
    // state -ben hasznaljuk ezert kell
    MatSnackBarModule,
    ...environment.extraPlugins,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-DE'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'de-DE'
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: AuthInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: Interceptor404,
    },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 8000 } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
/*
    ngcCookieContentService: NgcCookieConsentService,
*/
    router: Router,
    @Inject(PLATFORM_ID) platformId: string,
    seoSocialShareService: SeoSocialShareService,
    http: HttpClient
  ) {
    console.log(`VERSION: ${environment.version}`);

    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('/assets/mdi.svg')
    );

    if (isPlatformServer(platformId)) {
      router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe((_event: unknown) => {
          // fix angular typing error
          const event = _event as NavigationEnd;

          http
            .get<SeoSocialShareData & { image_alt: string }>(
              `${environment.backendUrl}/seo-data?url=${environment.appUrl}${event.url}`
            )
            .pipe(tap((data) => console.error(data)))
            .subscribe(
              (seoData: SeoSocialShareData & { image_alt: string }) => {
                seoSocialShareService.setData({
                  ...seoData,
                  image: `${environment.appUrl}${seoData.image}`,
                  imageAuxData: { alt: seoData.image_alt },
                  url: event.url,
                });
              }
            );
        });
    }
  }
}
