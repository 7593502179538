import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthState } from '@citydeals/auth';
import { isNil } from '@citydeals/common';
import { environment } from '@citydeals/env';
import { CheckoutEvent, ThankYouEvent, TrackService } from '@citydeals/track';
import { Store } from '@ngxs/store';
import { BasketState } from '..';
import { BasketPaymentPayload } from './model/basket-payment-payload.model';

@Injectable({
  providedIn: 'root',
})
export class BasketService {
  constructor(
    private httpClient: HttpClient,
    private trackService: TrackService,
    private store: Store
  ) {}

  paymentInit(payload: BasketPaymentPayload) {
    return this.httpClient.post<{ data: { redirect: string } }>(
      `${environment.backendUrl}/payment/init`,
      payload
    );
  }

  trackSteps(step: number) {
    const offers = this.store.selectSnapshot(BasketState.offers);
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);
    const products = offers.map((offer) => ({
      name: offer.voucherTitle,
      id: offer.id,
      price: offer.finalPrice,
      quantity: offer.quantity,
    }));

    const event = new CheckoutEvent(currentUser?.userId, step, products);
    this.trackService.track(event);
  }

  trackPayment(payRef: string) {
    const offers = this.store.selectSnapshot(BasketState.offers);
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);
    const currentAddress= this.store.selectSnapshot(BasketState.addressForm);
    const customerEmail = !isNil(currentUser) ? currentUser.email : currentAddress?.email || '';
    const revenue = offers.reduce((prev, curr) => {
      prev += curr.finalPrice;
      return prev;
    }, 0);
    const products = offers.map((offer) => ({
      name: offer.voucherTitle,
      id: offer.id,
      price: offer.finalPrice,
      quantity: offer.quantity,
    }));
    const event = new ThankYouEvent(
      currentUser?.userId,
      customerEmail,
      payRef,
      revenue,
      products
    );

    this.trackService.track(event);
  }
}
