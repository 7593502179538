import { UserPasswordService } from './user-password.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'frontend-user-activate',
  templateUrl: './user-activate.component.html',
  styleUrls: ['./user-activate.component.scss'],
  providers: [UserPasswordService],
})
export class UserActivateComponent implements OnInit {
  hash!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userPasswordService: UserPasswordService
  ) {}

  ngOnInit() {
    this.hash = this.route.snapshot.params['id'];
    this.userPasswordService
      .activateUser(this.hash)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(['/auth/login']);
      });
  }
}
