import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoverDirective } from './hover.directive';
import { HoverAddClassDirective } from './hover-add-class.directive';

@NgModule({
  declarations: [HoverDirective, HoverAddClassDirective],
  imports: [CommonModule],
  exports: [HoverDirective, HoverAddClassDirective],
})
export class HoverDirectiveModule {}
